<template>
  <div class="c-addressCard">
    <Icon
      :data="require(`@icons/location.svg`)"
      width="30"
      height="25"
    />
    <div class="c-addressCard__addressBox">
      <Typography
        class="-fontBold"
        v-text="props.address.name"
        variant="body1"
      />
      <Typography
        v-text="`${address.address}, ${address.city}`"
        variant="body1"
      />
    </div>
    <BaseButton
      class="c-addressCard__editCta"
      :btn-label="isMobile ? '' : $t('common.ctaLabels.change')"
      :icon="isMobile ? 'edit' : ''"
      @click="emit('handleModal', address)"
    />
    <BaseButton
      color="muted"
      :btn-label="isMobile ? '' : $t('common.ctaLabels.delete')"
      :icon="isMobile ? 'delete' : ''"
      @click="emit('confirmDelete', address.id)"
    />
    <small
      class="c-addressCard__badge"
      v-if="address.default_billing || address.default_delivery"
      v-text="$t(
        `widgets.profileAddress.type-${address.default_billing}${address.default_delivery}`)"
    />
  </div>
</template>

<script setup>
import { inject } from 'vue';

const props = defineProps({
  address: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits([ 'confirmDelete', 'handleModal' ]);
const isMobile = inject('isMobile');

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-addressCard {
  @include mx.d-flex('space-between', 'center');

  position: relative;

  margin-bottom: 8px;
  padding: 30px;

  border: 2px solid var(--#{ v.$variable-prefix}bg-02);
  border-radius: v.$base-border-radius;

  &__addressBox {
    margin-left: 20px;
  }

  &__editCta {
    margin-left: auto;
  }

  &__badge {
    @include mx.use-color('bodyColorDt');
    @include mx.use-bg-color('badge');

    position: absolute;
    top: 0;
    right: 0;

    padding: 2px 10px;

    letter-spacing: 0.24px;

    border-radius: 0 4px 0 16px;
  }
}

</style>
