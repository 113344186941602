<template>
  <div class="c-prompt">
    <span class="c-prompt__text">
      Are you sure?
    </span>
    <button
      class="c-prompt__accept"
      @click.stop="onClick"
      :disabled="props.showLoader"
      v-text="'YES'"
    />
    <span
      class="c-prompt__reject"
      v-text="'NO'"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  showLoader: {
    type: Boolean,
  },
});

const emit = defineEmits([ 'click' ]);

const onClick = () => {
  emit('click');
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/variables" as v;
@use "src/assets/styles/abstracts/mixins" as mx;

.c-prompt {
  @include mx.d-flex(space-between, center);

  &__text {
    font-weight: 600;
  }

  &__accept {
    margin-left: auto;
  }

  &__accept,
  &__reject {
    @include mx.use-color('muted');
    @include mx.font-size(12px, 15px);
    @include mx.use-bg-color(transparent);

    width: max-content;
    padding: 10px 4px;

    cursor: pointer;
    transition: color 300ms ease-in-out;
    letter-spacing: 0.3px;
    text-transform: uppercase;

    border: 2px solid transparent;
    border-radius: v.$base-border-radius;

    font-weight: 500;

    &:hover {
      @include mx.use-color('primary');
    }
  }
}
</style>
