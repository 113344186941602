<template>
  <Page :title="$t('widgets.profileAddress.title')">
    <SectionContainer class="l-profileAddress">
      <BaseButton
        :to="$i18nRoute({name: PROFILE})"
        icon="prev"
        icon-width="14"
        :btn-label="$t('common.ctaLabels.back')"
        color="muted"
      />
      <div class="h-pv-10">
        <Typography
          v-text="$t('widgets.profileAddress.title')"
          variant="subtitle1"
        />
        <Typography
          v-text="$t('widgets.profileAddress.subtitle')"
          variant="body1"
        />
      </div>

      <AddressCard
        class="c-addressCard"
        v-for="(addr) in userAddresses"
        :key="addr.id"
        :address="addr"
        @confirmDelete="confirmDelete"
        @handleModal="handleModal"
      />
      <div class="h-p-10">
        <BaseButton
          icon="plus"
          icon-width="16"
          :btn-label="$t('widgets.profileAddress.addAddress')"
          @click="handleModal(UserAddressModel)"
        />
      </div>
    </SectionContainer>
    <ModalAddressForm />
  </Page>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useStore } from 'vuex';

import ModalAddressForm from '@/components/ModalAddressForm.vue';
import Page from '@/components/Page.vue';
import AddressCard from '@/components/common/Card/AddressCard.vue';
import DeletePrompt from '@/components/common/DeletePrompt.vue';
import SectionContainer from '@/components/common/SectionContainer.vue';
import { UserAddressModel } from '@/interface/model';
import { toast } from '@/toast-notification';

const store = useStore();
const { PROFILE } = inject('routeNames');
const userAddresses = computed(() => store.state.auth.user.addresses);
const showLoader = computed(() => store.state.showLoader);

const handleModal = addr => {
  store.dispatch('auth/setSelectedAddressForEdit', addr);
  store.dispatch('overlays/setModal', 'AddressModal');
};

const confirmDelete = id => {
  const content = {
    component: DeletePrompt,
    props: { showLoader },
    listeners: {
      click: () => {
        store.dispatch('auth/deleteCustomerAddress', id);
      },
    },
  };

  // id - it's used to dismiss toast
  toast(content, {
    timeout: false, id: 'deletePrompt', icon: false, closeButton: false,
  });
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.l-profileAddress {
  padding-top: 100px;
  padding-bottom: 135px;
}
</style>
